// export const BASE_URL = "https://api-pls-dev.premcoprecast.com";
export const BASE_URL = "https://api-pls-alsafwa.arcplangroup.com";
export const USER_PLATFORM_SESSION_VERSION_NUMBER = "1.4";
export const ESTABLISHMENT_TOKEN = "f92dfb74-9cb9-44ad-b5ad-a946c3d1018f"; // Ahmed Omar & AbdElKarim (06/04/2022)

const HOME_FOOTER_STATUS = true;
const map_URL =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3427.892249006654!2d31.011824214638043!3d30.777603490520747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f7c9159ad2ff21%3A0x58c063ffe3093e2f!2z2KPZg9in2K_ZitmF2YrYqSDYp9mE2LXZgdmI2Kkg2YTZhNiq2K_YsdmK2Kg!5e0!3m2!1sen!2seg!4v1655546651789!5m2!1sen!2seg";
const map_STATUS = true;
const faceBook_URL = "https://www.facebook.com/Elsafwaacademy/";
const faceBook_STATUS = true;
const twitter_URL = "https://www.google.com";
const twitter_STATUS = false;
const linkedin_URL = "https://www.google.com";
const linkedin_STATUS = false;
const whatsApp_URL = "https://www.google.com";
const whatsApp_STATUS = false;
const youTube_URL = "https://www.google.com";
const youTube_STATUS = false;

const projectAddress1_STATUS = true;
const projectPhone1_STATUS = true;
const projectPhone2_STATUS = false;
const projectPhone3_STATUS = false;
const projectEmail1_STATUS = false;
const projectEmail2_STATUS = false;

export const PAGE_SIZE = 50;
export const IMPOSSIBLE_TOKEN = 0;
export const MAX_ROWS_TEXTAREA = 100;
export const DEFAULT_DIGITS = 2;

export const SOCIAL = {
  HOME_FOOTER_STATUS,
  map_URL,
  map_STATUS,
  faceBook_URL,
  faceBook_STATUS,
  twitter_URL,
  twitter_STATUS,
  linkedin_URL,
  linkedin_STATUS,
  whatsApp_URL,
  whatsApp_STATUS,
  youTube_URL,
  youTube_STATUS,
};
export const BASIC_CONTACTS = {
  projectAddress1_STATUS,
  projectPhone1_STATUS,
  projectPhone2_STATUS,
  projectPhone3_STATUS,
  projectEmail1_STATUS,
  projectEmail2_STATUS,
};

export const STATUS = {
  SUCCESS: 200, // success operation
  INVALID_TOKEN: 498, //invalid token
  CATCH: 500, // catch
  NO_CONTENT: 204, // no data
  ERROR: 406, // validation
  VERSION: 306, // error version
  NO_INTERNET: 420, // error version
  INVALID_LOGIN_CODE: 499, // error login code
};

//#region DefaultPages & Languages
export const DEFAULT_PAGES = {
  notLoggedIn: "Home",
  loggedIn: "SettingsOthers",
};

// ##duplicated in functions file
export const LANGUAGES = {
  list: ["ar-EG", "en-US", "und"],
  default: "ar-EG",
  arEG: "ar-EG",
  enUS: "en-US",
  und: "und",
  defaultCountryCode: "+20",
  defaultCountryCodeName: "EG",
};
//#endregion DefaultPages & Languages

//#region layoutDisplaies
export const VIEW_TYPES = {
  default: "table",
  table: "table",
  cards: "cards",
  list: ["table", "cards"],
};
//#endregion layoutDisplaies

//#region News
export const NEWS_MEDIA_TYPES = {
  image: "MTT-1",
  video: "MTT-2",
};
//#endregion News

//#region Reports
export const FILTER_REPORT_TYPES = {
  Default: "Group",
  Group: "Group",
  Place: "Place",
  Employee: "Employee",
  Student: "Student",
  Attendance: "Attendance",
};
//#endregion Reports

export const VALIDATION = {
  MIN_LENGTH_NAME: 3,
  MAX_LENGTH_NAME: 400,
  MIN_LENGTH_TEXT: 0,
  MAX_LENGTH_TEXT: 1000000,
};

export const KEY_CODE = {
  backspace: 8,
  backspaceMob: 229,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
};
